/*
  Entry point for the Thai site
*/

$font-family-base: 'Sarabun', sans-serif;
$headings-font-family: 'Sarabun', sans-serif;
$font-weight-normal: 300;
$font-size-base: 1.25rem;
$line-height-base: 1.7;

$nav-font-size: $font-size-base;
$h1-font-size: $font-size-base * 1.35;
$h1-font-size-lg: 3.5rem;

/* $h5-font-size is used for the product names on the Products page. */
$h5-font-size: $font-size-base * 1.15;

$form-check-input-margin-y: 1rem;

@import 'main';


/** GOOGLE FONTS
    https://fonts.google.com/share?selection.family=Sarabun:wght@300;400;700
    https://gwfh.mranftl.com/fonts/sarabun?subsets=latin,thai

    Sarahubun:
      - Light (300)
      - Regular (400)
      - Bold (700)
 */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/thai/sarabun-v15-latin_thai-300.woff2') format('woff2');
}
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/thai/sarabun-v15-latin_thai-regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/fonts/thai/sarabun-v15-latin_thai-700.woff2') format('woff2');
}


h1, .h1 { line-height: 1.2 }

%tab {
  font-size: $font-size-base * 1.15;
}

#header-navigation {
  .menu-grid-category-description { line-height: $line-height-base }
}

#mega-sidebar-menu .item>.section-title { font-size: $h5-font-size }

#ma-grid {
  .card-title { font-size: $font-size-base }
  .card-subtitle { font-size: $font-size-base * .9 }
}
